import React, { useMemo, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FiX } from 'react-icons/fi';
import { toast } from 'react-toastify';

import { isEmpty } from 'lodash';
import moment from 'moment';

import { SuccessModal, CancelModal, LoadingIndicator } from '@/components';
import { SelectUnit, InspectionSubgroup, Summary, Finish, InspectionDraftModal, IndicatorsModal } from './components';

import useAppContext from '@/contexts/AppContext';
import useInspectionContext from '@/contexts/InspectionContext';

import { TWizardStepButtonType, TWizardStepType } from '@/typings/wizard';
import { TInspectionSubgroupApiResponse } from '@/typings/api';

import { requestSendInspectionAnswers } from '@/services/inspection';
import { calculateProgressBarPercentage } from '@/utils/functions';

import { createAnswersArrayForApi } from './functions';
import * as S from './styles';
import { LOCALSTORAGE_KEYS } from '@/utils/constants';

const Inspection: React.FC = () => {

	const navigate = useNavigate();
	const {
		theme
	} = useAppContext();
	const {
		units,
		wizardSteps,
		currentWizardStep,
		selectedUnit,		
		inspectionObservation,

		setIsUserInUnit,
		getWizardSteps,
		getUnits,
		makeUseOfInspectionDraft,
		
		increaseWizardStep,
		decreaseWizardStep,
		clearInspectionData
	} = useInspectionContext();

	const [ inspectionStartDate, setInspectionStartDate ] = useState<Date>();

	const [ isLoadingButton, setIsLoadingButton ] = useState<boolean>(false);
	const [ isLoadingInspectionData, setIsLoadingInspectionData ] = useState<boolean>(false);
	const [ isCancelModalVisible, setIsCancelModalVisible ] = useState<boolean>(false);
	const [ isSuccessModalVisible, setIsSuccessModalVisible ] = useState<boolean>(false);
	const [ isInspectionDraftModalVisible, setIsInspectionDraftModalVisible ] = useState<boolean>(false);
	const [ isIndicatorsModalVisible, setIsIndicatorsModalVisible ] = useState<boolean>(false);

	const handleFinishInspection = async () => {
		// const isDevEnv = process.env.NODE_ENV === 'development' || window.location.origin === 'https://sandbox-vialaser-coordenacao.vialaser.com.br';
		// if (!isDevEnv && !isUserInUnit) return toast.error('Não é possível prosseguir com o checklist de vistoria. Sua localização não condiz com a localização da unidade.');

		setIsLoadingButton(true);
		toast.dismiss();

		try {
			const answers = createAnswersArrayForApi(wizardSteps);
			await requestSendInspectionAnswers(
				selectedUnit.idUnidade, 
				moment(inspectionStartDate!).format('DD/MM/YYYY HH:mm:ss'), 
				true, // isDevEnv ? true : isUserInUnit, 
				inspectionObservation, 
				answers
			);

			localStorage.removeItem(LOCALSTORAGE_KEYS.INSPECTION);
			setIsSuccessModalVisible(true);
		} catch (e) {
			const error: any = e;
			const defaultErrorMessage = 'Não foi possível finalizar a vistoria. Tente novamente!';
			
			if (!error.response) toast.error(defaultErrorMessage);
			toast.error(error.response.data.message);
		}
		
		setIsLoadingButton(false);
	}

	const handleExitInspection = () => {
		clearInspectionData();

		setIsUserInUnit(false);
		setIsLoadingButton(false);
		setIsCancelModalVisible(false);
		setIsSuccessModalVisible(false);

		localStorage.removeItem(LOCALSTORAGE_KEYS.INSPECTION);
		navigate('/');
	}

	const handleChangeCurrentStep = (buttonType: TWizardStepButtonType) => {
		const isPreviousButton = buttonType === 'PREVIOUS';
		if (isPreviousButton) return decreaseWizardStep();

		const isFirstStep = currentWizardStep === 0;
		const isLastStep = currentWizardStep + 1 === wizardSteps.length;

		if (isFirstStep) setInspectionStartDate(moment().toDate());
		if (isLastStep) return handleFinishInspection();
		
		increaseWizardStep();
	}

	const removeInspectionDraft = () => {
		localStorage.removeItem(LOCALSTORAGE_KEYS.INSPECTION);
		setIsInspectionDraftModalVisible(false);
	}

	const isButtonDisabled = (buttonType: TWizardStepButtonType) => {
		const isPreviousButton = buttonType === 'PREVIOUS';

		if (isLoadingButton) return true;
		if (isPreviousButton) return false;

		const isUnitSelection = step.type === 'SELECT_UNIT';
		const isInspectionGroup = step.type === 'INSPECTION_GROUP';

		if (isUnitSelection) return isEmpty(selectedUnit);

		if (isInspectionGroup) {
			const isDisabled = step.inspectionSubgroups?.some(subgroup => {
				return subgroup.perguntas.some(question => !question.respostaSelecionada);
			});

			return isDisabled;
		}

		return false;
	}

	const setClusterColor = () => {
		if (isEmpty(selectedUnit)) return '';

		const { cluster, porcentagemAtingimentoMeta } = selectedUnit;
		if (!cluster || !porcentagemAtingimentoMeta) return '';

		if (porcentagemAtingimentoMeta < 60) return 'red';
		if (porcentagemAtingimentoMeta >= 61 && porcentagemAtingimentoMeta < 90) return 'yellow';
		
		return 'green';
	}

	// useEffect(() => {
	// 	const isNextToLastStep = currentWizardStep + 1 === (wizardSteps.length - 1);
	// 	if (isNextToLastStep) checkIfUserIsInUnit();

	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [currentWizardStep]);

	useEffect(() => {
		const getInspectionData = async () => {
			let inspectionDraft: any = localStorage.getItem(LOCALSTORAGE_KEYS.INSPECTION)
			if (inspectionDraft) {
				inspectionDraft = JSON.parse(inspectionDraft);
				setIsInspectionDraftModalVisible(
					inspectionDraft &&
					inspectionDraft.selectedUnit &&
					inspectionDraft.wizardSteps
				);
			}

			setIsLoadingInspectionData(true);
			await getUnits();
			await getWizardSteps();
			setIsLoadingInspectionData(false);
		}

		getInspectionData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const step = useMemo(() => wizardSteps[currentWizardStep], [wizardSteps, currentWizardStep]);

	if (isLoadingInspectionData) {
		return (
			<S.CenteredContainer>
				<LoadingIndicator
					width={ 50 }
					height={ 50 }
					color={ theme.colors.primary }
				/>

				<p>Carregando dados...</p>
			</S.CenteredContainer>
		);
	}

	if (wizardSteps.length === 0 || units.length === 0) {
		return (
			<S.CenteredContainer>
				<div className="error">
					<h1 className="error__title">
						Alerta!
					</h1>

					<p className="error__mess">
						Parece que algo de errado aconteceu enquanto os dados para a vistoria eram trazidos.
					</p>
				</div>

				<Link to="/" className="button button-red">
					Voltar
				</Link>
			</S.CenteredContainer>
		);
	}

	return (
		<S.Inspection
			withTwoButtons={ step.buttons.length > 1 }
			progressBarPercentage={ calculateProgressBarPercentage(wizardSteps.length, currentWizardStep) }
		>
			<header className="header">
				<div>
					<h1 className="header__title">
						{ !isEmpty(selectedUnit) ? selectedUnit.descricao : 'Vistoria de Unidade' }
					</h1>

					<h2 className="header__subtitle">
						Passo { currentWizardStep + 1 } de { wizardSteps.length }
					</h2>
				</div>

				<button className="header__button" onClick={ () => setIsCancelModalVisible(true) }>
					<FiX />
				</button>
			</header>

			<section className="progress">
				<div className="progress__bar">
					<div className="progress__bar-indicator" />
				</div>

				<div className="indicator">
					<div className="indicator__box">
						<div className="indicator__triangle" />

						<div className="indicator__percentage">
							<span>
								{ calculateProgressBarPercentage(wizardSteps.length, currentWizardStep) }
							</span>
						</div>
					</div>
				</div>
			</section>

			<main className="main">
				<div className="content">
					{
						(!isEmpty(selectedUnit) && !!selectedUnit.cluster) && (
							<div className="cluster">
								<span className={`cluster__text cluster__text-${setClusterColor()}`}>
									Cluster { selectedUnit.cluster }
								</span>
							</div>
						)
					}

					<div className="content__header">
						<h2 className="content__title">
							{ step.title }
						</h2>

						{
							step.hasIndicator && (
								<button
									className="content__button"
									onClick={ () => setIsIndicatorsModalVisible(true) }
								>
									Indicadores
								</button>
							)
						}

					</div>

					<InspectionContent
						type={ step.type }
						subgroups={ step.inspectionSubgroups }
					/>
				</div>

				<footer className="footer">
					{
						step.buttons.map(button => (
							<S.StepAction
								key={ button.id }
								btnType={ button.type }
								disabled={ isButtonDisabled(button.type) }
								onClick={ () => handleChangeCurrentStep(button.type) }
							>
								{ isLoadingButton && button.type === 'NEXT' ? <LoadingIndicator /> : button.text }	
							</S.StepAction>
						))
					}
				</footer>
			</main>

			<SuccessModal
				visible={ isSuccessModalVisible }
				title={ 'Sucesso!' }
				message={ 'A vistoria foi realizada com sucesso.' }
				onCloseModal={ handleExitInspection }
			/>

			<CancelModal
				visible={ isCancelModalVisible }
				title={ 'Cancelar vistoria!' }
				message={ 'Tem certeza que quer cancelar a vistoria?' }
				leftButton={{
					text: 'Não',
					onClick: () => setIsCancelModalVisible(false)
				}}
				rightButton={{
					text: 'Sim',
					onClick: handleExitInspection
				}}
			/>

			<InspectionDraftModal
				visible={ isInspectionDraftModalVisible }
				onClose={ removeInspectionDraft }
				onAccept={ () => {
					makeUseOfInspectionDraft();
					toast.success('Vistoria recuperada!');
					setIsInspectionDraftModalVisible(false);
				} }
			/>

			{
				isIndicatorsModalVisible && (
					<IndicatorsModal 
						pillarIndicator={ step.pillarIndicator! }
						onClose={ () => setIsIndicatorsModalVisible(false) } 
					/>
				)
			}
		</S.Inspection>
	);

}

interface InspectionContentProps {
	type: TWizardStepType;
	subgroups?: Array<TInspectionSubgroupApiResponse>;
}

const InspectionContent: React.FC<InspectionContentProps> = ({ type, subgroups }) => {

	if (type === 'SELECT_UNIT') return <SelectUnit />;
	if (type === 'INSPECTION_GROUP') return <InspectionSubgroup subgroups={ subgroups! } />;
	if (type === 'SUMMARY') return <Summary />
	if (type === 'FINISH') return <Finish />

	return <>Content!</>

}

export default Inspection;
